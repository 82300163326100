import React, { Component }  from 'react';
import { useState, useEffect } from 'react';

import HomeNormalSize from './normalSize'
import HomeSmallSize from './smallSize'

export default function Home (){
      const [windowSize, setWindowSize] = useState([
        window.innerWidth,
      ]);

      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

     console.log(windowSize[0]);
     return windowSize[0] > 720 ? <HomeNormalSize/> : <HomeSmallSize/>;

}