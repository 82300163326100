import './App.css';

import Sidebar from './components/Sidebar';

import Header from './components/Header';

import PixelArtRobotics from './components/PixelArtRobotics';

import Chemistry from './components/Chemistry';

import About from './components/About';

import Home from './pages/home'


function App() {
  return (
        <Home/>
  );
}

export default App;
