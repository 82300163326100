import React, { Component }  from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Sidebar from '../Sidebar';

import './styles.css';

export default class Header extends Component {

    constructor(props) {
        super(props);
      }
      render() {
        return(
            <Navbar variant="dark" sticky="top">
              <Container>
                <Navbar.Brand href="#home">For Fun Lab</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="#home">Pixel art & Robotics</Nav.Link>
                    <Nav.Link href="#link">Chemistry</Nav.Link>
                    <Nav.Link href="#link">About the lab</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
      );
    }
}


