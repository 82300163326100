import { useEffect } from 'react';

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    //script.setAttribute('custom-element', 'amp-instagram')

    document.body.appendChild(script);
  }, [url]);
};

export default useScript;